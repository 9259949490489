import React, {useEffect,useState} from 'react';
import { CheckboxV2, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";

function TimeFlex(props) {

   
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    ////If selected environment is cannot use the checkbox, reset value to default
     useEffect(() => {
        if(should_display_TimeFlex_checkbox(props) === false){
            props.setTimeFlex(false)
        }        
    }, [props.environmentSelected])

    
     //If selected environment is cannot use the checkbox, hide
     if(should_display_TimeFlex_checkbox(props) === false){
        return null
    }

    return <>
        {/* TimeFlex */}
      
        <tr className='reqForm_tablerow'>
             <td className='td_row_leftvalue'>
                  
                    <CheckboxV2 className="verint-CheckboxV2-regular" id="TimeFlex" style={{marginTop :"3px"}}
                             name="TimeFlex" type = "checkbox" value = {props.timeFlex}  disabled={props.disabled} checked={props.checked}
                            onChange={(e) => {props.setTimeFlex(!props.timeFlex);}} ></CheckboxV2>
                            
                    <Label id="TimeFlex" className="verint-regular-label"    forhtml="TandC"> Timeflex </Label>
                    <OverlayText tooltipText={" Select this option if you require the TimeFlex"}></OverlayText>

              </td>
        </tr>
    </>        
}

export default TimeFlex;

function should_display_TimeFlex_checkbox(props)
{
    //checking the editform property from current request
    if (props.editForm === "true") {
        return true;
    }

    var jsonData =props.environemntRecords

    for( var i =0; i < jsonData.Count; i++)
    {
        //console.log("grp name",jsonData.Items[i].group_name , "temp", jsonData.Items[i].template_id)
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].TimeFlex === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}