import React, {useState, useEffect} from 'react';
import { CheckboxV2, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";

/* Set the value of Call Summary checkbox */
function CallSummaryCheckBox(props) {
    
    const [checked, setChecked] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    useEffect(() => {
        if(should_display_callSummary_checkbox(props) === false){
            setChecked(false)
        } 
        // checking for the editform property from current req 
        if(props.editForm === "true"){

        setChecked(true)
    }

    }, [props.environmentSelected])

    //If selected environment cannot use the checkbox, hide
    if(should_display_callSummary_checkbox(props) === false){
        return null
    }

   

    // If DaVinci Checkbox is set to true, only then display Interaction Wrap Up Bot Checkbox
        return <>
        {/*  Call Summary Checkbox  */}
        <tr className='reqForm_tablerow'>
            <td className='td_row_leftvalue'>
                <CheckboxV2 className="verint-CheckboxV2-regular" id="CallSummary" style={{marginTop :"3px"}} disabled={props.disabled}
                    name="CallSummary" type = "checkbox"  value = {checked}  onChange={(e)=>{setChecked(!checked)}} checked={checked} ></CheckboxV2>
                    
                <Label id="callSummary_label"className="verint-regular-label"   forhtml="CallSummary">Interaction Wrap Up Bot  </Label>
                <OverlayText tooltipText={"Select this option to include Call Summary"}></OverlayText>
            </td>
        </tr>
        

    </> 
}



export default CallSummaryCheckBox;

function should_display_callSummary_checkbox(props)
{
    //checking the editform property from current request

    if (props.editForm === "true") {
        return true;
    }


    if(props.daVinciChecked === true){
        return true
    }
    //Checking props from EditForm Actions
    if(props.editForm === "true"){
        return true
    }
    return false
}