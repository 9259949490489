import React, {useState, useEffect} from 'react';
import { Popover ,CheckboxV2, LuxStylesProvider, Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";

function KMAutomationCheckbox(props) {

    //console.log("KMAutomationCheckbox!!",props)
    const [isDisabled, setIsDisabled] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

     // If selected environment is cannot use the checkbox, reset value to default
     useEffect(() => {
        if(should_display_KMAutomation_checkbox(props) === false){
            props.setKMAutomation(false)
        }   
           
    }, [props.environmentSelected])
     
     // If selected environment is cannot use the checkbox, hide
     if(should_display_KMAutomation_checkbox(props) === false){
        return null
    }

    return <>
        {/*  Live Recording  */}
        <tr className='reqForm_tablerow'>
        <td className='td_row_leftvalue'>
                        
            <CheckboxV2 className="verint-CheckboxV2-regular" id="KMAutomation" style={{marginTop :"3px"}}
                    name="KMAutomation" type = "checkbox"  value = {props.KMAutomation}  
                    disabled={props.disabled} checked={props.checked}
                    onChange={(e) => {props.setKMAutomation(!props.KMAutomation);}}
            ></CheckboxV2>         
            <Label id="KMAutomation_label"className="verint-regular-label" forhtml="KMAutomation">KM Automation </Label>
            <OverlayText tooltipText={"Select this option if you want to enable KM Automation"}></OverlayText>            
        </td>
        </tr>
            
    </>        
}

export default KMAutomationCheckbox;

function should_display_KMAutomation_checkbox(props)
{
    //checking the editform property from current request
    if (props.editForm === "true") {
        return true;
    }
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].KM_Automation  === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}

